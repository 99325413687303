









import Vue from 'vue'

import Date from '@/components/Card/History/Date.vue'
import HumanLogs from '@/components/Card/History/HumanLogs.vue'
import HumanHistory from '@/components/Card/History/Human.vue'
import SelectThing from '@/components/Select/Thing.vue'

export default Vue.extend({
  components: {
    Date,
    HumanLogs,
    HumanHistory,
    SelectThing
  }
})
