







import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'

import { IStateHistory, IMotionEventRange, IRecentHistoryEventGraph } from '@/store/modules/history'
import { IStateThing } from '@/store/modules/thing'
import chartOptions from '@/utils/charts/motionChart'
import { chart } from 'highcharts'

@Component({
  components: {
    Chart
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateHistory>('history', {
      hasHumanMotionHistory: (state: IStateHistory) =>
        state.humanMotionHistory && state.humanMotionHistory.length > 0
    }),
    ...mapGetters('history', ['humanMotionEvents', 'humanMotionRanges'])
  }
})
export default class ChartComponent extends Vue {
  private chartOptions: any = chartOptions
  private hasHumanMotionHistory!: boolean
  private humanMotionEvents!: IRecentHistoryEventGraph[]
  private humanMotionRanges!: IMotionEventRange[]

  @Watch('serial')
  cleanData() {
    const { chartHumanReference } = this.$refs
    if (!chartHumanReference) return

    const chartSerie = (chartHumanReference as any).chart.series[0]
    chartSerie.setData([])
  }

  @Watch('serial')
  setData() {
    const { chartHumanReference } = this.$refs
    if (!this.humanMotionEvents || !chartHumanReference) return

    const chart = (chartHumanReference as any).chart
    const chartSerie = (chartHumanReference as any).chart.series[0]

    chartSerie.update({ data: this.humanMotionEvents.reverse(), name: 'Human Motion' })
    this.humanMotionRanges.forEach((eventRange: IMotionEventRange) => {
      chart.xAxis[0].addPlotBand(eventRange)
    })
  }

  mounted() {
    this.setData()
  }
}
