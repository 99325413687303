import moment from 'moment'
import { TURBO_TRESHOLD, CHART_HEIGHT, TIMEZONE_OFFSET } from '@/utils/constant'

export default {
  chart: {
    height: CHART_HEIGHT,
    backgroundColor: 'transparent',
    scrollablePlotArea: {
      scrollPositionX: 1,
      opacity: 0
    },
    zoomType: 'x'
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  series: [
    {
      color: 'transparent',
      borderColor: 'transparent',
      showInLegend: false,
      marker: {
        fillColor: 'white',
        lineWidth: 2,
        lineColor: null,
        radius: 5
      },
      turboThreshold: TURBO_TRESHOLD
    }
  ],
  time: {
    timezoneOffset: TIMEZONE_OFFSET
  },
  title: null,
  tooltip: {
    formatter: function(): string {
      const { x, z, series } = (this as any).point
      const { name } = series

      return `
        <b>${name}</b><br/>
        <b>Start:</b> ${moment
          .utc(x)
          .local()
          .format('HH:mm:ss')} <br/>
        <b>Stop:</b> ${moment
          .utc(z)
          .local()
          .format('HH:mm:ss')}<br/>
      `
    }
  },
  xAxis: {
    type: 'datetime',
    labels: {
      style: {
        color: 'white',
        fontSize: '10px'
      }
    },
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S'
    },
    tickPixelInterval: 75
  },
  yAxis: {
    data: [],
    title: null,
    visible: false,
    min: 0,
    max: 1
  }
}
