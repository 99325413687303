




import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  computed: {
    date: function() {
      return moment()
        .locale(this.$i18n.locale)
        .format('D MMMM YYYY')
    }
  }
})
