var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card logs" },
    [
      _vm.getHumanMotionLogs.length === 0
        ? _c("p", [_vm._v(_vm._s(_vm.$t("Card.History.NoMotion")))])
        : _vm._l(_vm.getHumanMotionLogs, function(motionLog, index) {
            return _c("div", { key: index, staticClass: "log" }, [
              _c(
                "div",
                { staticClass: "picto" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "motion-icon ",
                    attrs: { icon: ["fas", "walking"] }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("Card.History.HumanMotion")))
                ]),
                _c("p", [
                  _c("span", { staticClass: "time" }, [
                    _vm._v(" " + _vm._s(motionLog.startTime) + " ")
                  ]),
                  _vm._v(" - " + _vm._s(motionLog.duration) + " ")
                ]),
                motionLog.ongoing
                  ? _c("p", [
                      _vm._v(_vm._s(_vm.$t("Card.History.OngoingEvent")))
                    ])
                  : _vm._e()
              ])
            ])
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }