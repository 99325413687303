









import Vue from 'vue'
import { mapState } from 'vuex'

import HumanChart from '@/components/Card/History/HumanChart.vue'
import { IStateThing } from '@/store/modules/thing'

export default Vue.extend({
  data() {
    return {
      loading: false
    }
  },
  components: {
    HumanChart
  },
  methods: {
    getHistory() {
      const hasSerial = this.$store.state.thing.serial
      if (!hasSerial) return

      this.loading = true
      this.$store.dispatch('history/getMotionEvents').finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    })
  },
  watch: {
    serial: function() {
      this.$store.commit('history/resetMotionHistory')
      this.getHistory()
    }
  },
  mounted() {
    this.getHistory()
  },
  beforeDestroy() {
    this.$store.commit('history/resetMotionHistory')
  }
})
